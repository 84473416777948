import React, { Component } from 'react';
import { compose } from 'recompose';


import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { withFirebase } from '../Firebase';

import SpreadFormBase  from '../SheetDayHistory';
import SpreadFormGuestBase  from '../SheetDayGuestHistory';
import * as ROLES from '../../constants/roles';

function CurrentDate() {

  return <div className='subtitle'>

<AuthUserContext.Consumer>

{authUser =>
  authUser ? (
    <div style={{ textAlign: 'center' }}>
 <h2>데이트레이딩 결산</h2>

    </div>
  ) : (
    <div style={{ textAlign: 'center' }}>
 <h2>데이트레이딩 결산</h2>
    </div>
  )
}



</AuthUserContext.Consumer>
 
</div> 
}


const DayHistoryPage = () => (
 
  <AuthUserContext.Consumer>

{authUser =>
    authUser && !!authUser.roles[ROLES.VIP] && !!((authUser.roles && authUser.roles.day && authUser.roles.day.seconds)>(Math.floor(Date.now() / 1000)))? (
    <div>
    <CurrentDate/>
    <SpreadFormBase />

    </div>
  ) : (
    <div>
    <CurrentDate/>
    <SpreadFormGuestBase />
    </div>
  )
}



</AuthUserContext.Consumer>
      
 
);



const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,

)(DayHistoryPage);




