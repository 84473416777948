import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const getHour = () => {
  const date = new Date();
  return date.getHours();
};

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => {
  const currentTime = getHour();
 
  return (
    <header class="container">
      <ul class="nav-list">
        {/* <li class="nav-item">
          <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.DAY} class="nav-link">
            데이
          </Link>
        </li>
        <li class="nav-item">
        <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.START} class="nav-link">
            시초
          </Link>
        </li>
        <li class="nav-item">
        <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.END} class="nav-link">
            종가
          </Link>
        </li> */}
        
        <li class="nav-item">
        <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.DAY_HISTORY}
            class="nav-link"
          >
            데이
          </Link>
        </li>
        <li class="nav-item">
        <Link to={(currentTime <8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.END_HISTORY}
            class="nav-link"
          >
            스윙
          </Link>
        </li>
        <li class="nav-item">
          <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.DAY} class="nav-link">
            월별데이
          </Link>
        </li>
        <li class="nav-item">
        <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.START} class="nav-link">
            월별스윙
          </Link>
        </li>
        {/* {!!authUser.roles[ROLES.ADMIN] && (
          <li>
            <Link to={ROUTES.ADMIN}>내정보</Link>
          </li>
        )} */}
        <li className="nav-item">
          <Link to={ROUTES.ACCOUNT} class="nav-link">
            설정
          </Link>
        </li>
        <li class="nav-item">
          <button
            type="button"
            class="nav-button"
            onClick={() =>
              window.open('https://t.me/bot_ai_stock', '_blank')
            }
          >
            만든이
          </button>
          <SignOutButton />
        </li>
        
        </ul>
      </header>
    )};



const NavigationNonAuth = () => (
  <header class="container">
  <ul class="nav-list">
  {/* <li class="nav-item">
      <Link to={ROUTES.DAY} class="nav-link">데이</Link>
    </li>
    <li class="nav-item">
      <Link to={ROUTES.START} class="nav-link">시초</Link>
    </li>
    <li class="nav-item">
      <Link to={ROUTES.END} class="nav-link">종가</Link>
    </li> */}
    <li class="nav-item">
      <Link to={ROUTES.DAY_HISTORY} class="nav-link">데이</Link>
    </li>
    <li class="nav-item">
      <Link to={ROUTES.END_HISTORY} class="nav-link">스윙</Link>
    </li>
    <li class="nav-item">
          <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.DAY} class="nav-link">
            월별데이
          </Link>
        </li>
        <li class="nav-item">
        <Link to={(currentTime < 8) || (currentTime > 20) ? ROUTES.SIGN_IN : ROUTES.START} class="nav-link">
            월별스윙
          </Link>
        </li>

    <li class="nav-item">
    <button type="button" class="nav-button"  onClick={() => window.open('https://t.me/bot_ai_stock', '_blank')}>만든이</button>

    </li>
  </ul>
  </header>
);

export default Navigation;
