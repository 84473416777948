import React, { Component } from 'react';
import axios from 'axios';
import { withFirebase } from '../Firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles } from '@material-ui/core/styles';

const CustomTableCell = withStyles(theme => ({
  head: {
    padding: '6px 10px 6px',
    fontSize: 14,
  },
  root: {
    borderBottom: "none",
    "@media (max-width: 375px)": {
      fontSize: 10,
    },
    fontSize: 14,
  },
  body: {
    "@media (max-width: 375px)": {
      padding: '8px 5px 8px',
    },
    padding: '6px 10px 6px',
    color: (props) => {
      if (props.color === "red") {
        return "red"; // 빨간색
      } else if (props.color === "blue") {
        return "blue"; // 파란색
      }
      return "inherit"; // 기본 색상
    },
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class SpreadFormGuestBase extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: [],
  };

  getMyData = async () => {
    let data = await axios.get(process.env.REACT_APP_END_G_HISTORY + process.env.REACT_APP_SPREADSHEET_ID);
    data = data.data.values;
    data.shift();
    this.setState({ data });
  };

  componentDidMount() {
    this.getMyData();
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell>날짜</CustomTableCell>
            <CustomTableCell>종목명</CustomTableCell>
            <CustomTableCell align="right">상황</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data.map((values, index) => (
            <TableRow key={index}>
              <CustomTableCell component="th" scope="row">
                {values[0]}
              </CustomTableCell>
              <CustomTableCell>{values[1]}</CustomTableCell>
              <CustomTableCell color={values[2] === '익절' ? 'red' : values[2] === '손절' ? 'blue' : 'inherit'} align="right">
                {values[2]}
              </CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withFirebase(SpreadFormGuestBase);
