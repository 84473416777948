import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext,withAuthorization, withEmailVerification } from '../Session';
import Messages from '../Messages';
import * as ROLES from '../../constants/roles';
import SpreadFormBase  from '../SheetMonthDay';

function CurrentDate() {

  return <div className='subtitle'>
    <div style={{ textAlign: 'center' }}>
 <h2>월별 데이</h2>
    </div>
</div> 
}


const DayPage = () => (
  <AuthUserContext.Consumer>
  {authUser => (
    <>
      {!authUser.roles[ROLES.SU] && (
        <div
          className='dd'
          style={{
            content: "",
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='350' height='100'%3E%3Ctext x='180' y='100' font-size='30' fill='gray' opacity='0.5' text-anchor='middle'%3E${authUser.email}%3C/text%3E%3C/svg%3E") repeat`,
            backgroundSize: "60px 60px",
            backgroundPosition: "-10px -10px",
            transform: "rotate(-15deg)",
            position: "fixed",
            top: "-300",
            left: "-100",
            width: "150%",
            height: "150%",
            zIndex: "-1"
          }}
        />
      )}
       {authUser.roles[ROLES.SU] && (
        <div
          className='dd'
          style={{
            content: "",
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='350' height='100'%3E%3Ctext x='180' y='100' font-size='30' fill='gray' opacity='0.5' text-anchor='middle'%3Ek-stock-ai.com%3C/text%3E%3C/svg%3E") repeat`,
            backgroundSize: "20px 20px",
            backgroundPosition: "-10px -10px",
            transform: "rotate(-15deg)",
            position: "fixed",
            top: "-300",
            left: "-100",
            width: "150%",
            height: "150%",
            zIndex: "-1"
          }}
        />
      )}
      <div className="" style={{ transform: "none" }}>
        <CurrentDate />
        <SpreadFormBase />
      </div>
    </>
  )}
</AuthUserContext.Consumer>
);



const condition = authUser =>
  authUser && !!authUser.roles[ROLES.VIP] && !!((authUser.roles && authUser.roles.day && authUser.roles.day.seconds)>(Math.floor(Date.now() / 1000))) ;


export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(DayPage);




