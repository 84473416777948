export const SIGN_IN = '/';
export const SIGN_UP = '/signup';
// export const SIGN_IN = '/signin';

export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';


export const DAY = '/day';
export const START = '/start';
export const END = '/end';
export const DAY_HISTORY = '/dayhistory';
export const END_HISTORY = '/endhistory';
