import React, { Component } from 'react';
import axios from 'axios';
import { withFirebase } from '../Firebase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { withStyles } from '@material-ui/core/styles';


const CustomTableCell = withStyles(theme => ({
  head: {
    padding: '6px 10px 6px',
    fontSize: 14,
  },
  root: {
    borderBottom: "none",
    "@media (max-width: 375px)": {
      fontSize: 10,
    },
    fontSize: 14,
  },
  body: {
    "@media (max-width: 375px)": {
      padding: '8px 5px 8px',
    },
    padding: '6px 10px 6px',
    color: (props) => {
      if (props.color === "red") {
        return "red"; // 빨간색
      } else if (props.color === "blue") {
        return "blue"; // 파란색
      }
      return "inherit"; // 기본 색상
    },
   
  },
  select: {
    fontSize: '5px', // 원하는 크기로 설정
    color: 'red', // 원하는 색상으로 설정
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});




class SpreadFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showDetails: false,
      selectedMonth: 'jan', 
    };
   
}

getDataForSelectedMonth = (selectedMonth, responseData) => {
  if (selectedMonth === 'jan') {
    // 선택한 월이 '현재(월)'인 경우, 가장 최근의 데이터를 사용합니다.
    const currentMonth = responseData[0];
    if (currentMonth) {
      return currentMonth.values || [];
    }
  } else {
    // 다른 월의 데이터를 찾아 반환합니다.
    const selectedData = responseData.find((monthData) =>
      monthData.range.toLowerCase().includes(selectedMonth.toLowerCase())
    );
    if (selectedData) {
      
      return selectedData.values || [];
    }
  }
  return [];
};


getMyData = async () => {
  const selectedMonth = this.state.selectedMonth;
  const apiEndpoint = 'https://phd36vb8ee.execute-api.ap-northeast-2.amazonaws.com/allday/stockallday';

  try {
    const response = await axios.post(apiEndpoint, null, {
      headers: {
        'x-api-key': 'rnnEywwG7RaBoLNmzkCny23Vxdu9iLJL9d6ck1FW',
      },
    });

    if (response.status === 200) {
      const responseData = JSON.parse(response.data.body);

       // 한글 월 이름을 영어로 변환
       const selectedMonthInEnglish = this.convertToEnglishMonth(selectedMonth);
       const selectedMonthData = this.getDataForSelectedMonth(selectedMonthInEnglish, responseData);

      if (Array.isArray(selectedMonthData)) {
        // Exclude the first row of data
        const dataWithoutFirstRow = selectedMonthData.slice(1);
        this.setState({ data: dataWithoutFirstRow });
      } else {
        console.error('Response data for the selected month is not an array:', selectedMonthData);
      }
    } else {
      console.error('Failed to fetch data:', response.status);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

// 한글 월 이름을 영어로 변환하는 함수
convertToEnglishMonth = (koreanMonth) => {
  const koreanMonths = [
    '1월', '2월', '3월', '4월', '5월', '6월',
    '7월', '8월', '9월', '10월', '11월', '12월'
  ];
  const englishMonths = [
    'jan', 'feb', 'mar', 'apr', 'may', 'jun',
    'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
  ];

  const index = koreanMonths.indexOf(koreanMonth);
  if (index !== -1) {
    return englishMonths[index];
  }

  return '';
};

toggleShowDetails = () => {
  this.setState((prevState) => ({
    showDetails: !prevState.showDetails,
  }));
};
generateMonthOptions = () => {
  const currentMonthIndex = new Date().getMonth();
  const allMonths = [
    '1월', '2월', '3월', '4월', '5월', '6월',
    '7월', '8월', '9월', '10월', '11월', '12월'
  ];

  const lastThreeMonthsIndices = [(currentMonthIndex + 12) % 12, (currentMonthIndex + 11) % 12, (currentMonthIndex + 10) % 12];

  const months = lastThreeMonthsIndices.map((index) => allMonths[index]);

  return allMonths;
};

// ...

handleMonthSelect = (event) => {
  const selectedMonth = event.target.value;
  this.setState({ selectedMonth }, () => {
    this.getMyData();
  });
};

componentDidMount() {
  const currentMonthIndex = new Date().getMonth();
  const months = this.generateMonthOptions();

  // 월 선택을 초기화할 때 데이터를 가져오도록 이동합니다.
  this.setState({ selectedMonth: months[0] }, () => {
    this.getMyData();
  });
}


render() {
  const { showDetails, selectedMonth, data } = this.state;
  const months = this.generateMonthOptions();

  

  return (
    <Table>
      <TableHead>
        <TableRow>
        <CustomTableCell colSpan={showDetails ? 6 : 3}>
  <Select
    label="월 선택"
    value={this.state.selectedMonth} 
    onChange={this.handleMonthSelect}
    size = "small"
    style={{ fontSize: '10px' }} 

  >
    {months.map((month, index) => (
      <MenuItem key={index} value={month}>
        {month}
      </MenuItem>
    ))}
  </Select>
</CustomTableCell>
<CustomTableCell style={{ textAlign: 'right' }}>
            <button onClick={this.toggleShowDetails}>
              {showDetails ? '접기' : '펼침'}
            </button>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      
        {this.state.data.map((values, index) => (
          <TableRow key={index}>
             <CustomTableCell style={{ fontSize: showDetails ? '7px' : '' }}>{`${values[0]}`}</CustomTableCell>
            <CustomTableCell style={{ fontSize: showDetails ? '7px' : '' }}>
  {showDetails ? (values[1].length > 4 ? values[1].slice(0, 4) + '.' : values[1]) : values[1]}
</CustomTableCell>
<CustomTableCell color={values[2] === '익절' ? 'red' : values[2] === '손절' ? 'blue' : 'inherit'} style={{ fontSize: showDetails ? '7px' : '' }}>
                  {values[2]}
                </CustomTableCell>
            {showDetails && (
              <>
                <CustomTableCell style={{ fontSize: '7px' }}>{values[3]}</CustomTableCell>
                <CustomTableCell style={{ fontSize: '7px' }}>{values[4]}</CustomTableCell>
                <CustomTableCell style={{ fontSize: '7px' }}>{values[5]}</CustomTableCell>
              </>
            )}
           <CustomTableCell>
  {index === 0 ? values[6] : (
    <span style={{
      color: values[6] && values[6].startsWith('-') ? 'blue' : 'red',
      fontSize: showDetails ? '7px' : ''
    }}>
      {values[6]}
    </span>
  )}
</CustomTableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
}
export default withFirebase(SpreadFormBase);
