import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  username: '',
  displayName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};



const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

 

class ProfileChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  

  onSubmit = event => {
    const {  username, photo } = this.state;

    this.props.firebase
    .doUpdateProfile({  displayName: username,  photoURL: photo})
    .then(() => {
      this.setState({ ...INITIAL_STATE });
      console.log("18181818" +displayName)
    })
    .catch(error => {
  
    });


    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const {
      username,
      email,

      error,
    } = this.state;

  

    return (

      <div className="loginBackground">
      
      <form className="loginright" onSubmit={this.onSubmit}>
      <h1>회원가입</h1>
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="닉네임"
        />
       
  
        <button  type="submit">
          회원가입 완료
        </button>

        {error && <p>이메일 양식이 다르거나 공백 확인 및 패스워드 단순하게 하시면 안됩니다. </p>}
        </form>
    </div>
    );
  }
}

export default withFirebase(ProfileChangeForm);
