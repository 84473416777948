import React, { Component } from 'react';
import { compose } from 'recompose';

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
import { withFirebase } from '../Firebase';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import ProfileChangeForm from '../ProfileChange';

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: {
        bitcoin: false,
        ethereum: false,
        ripple: false,
      },
    };
  }

  handleCopyClick = (type, address) => {
    const el = document.createElement('textarea');
    el.value = address;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this.setState((prevState) => ({
      copied: { ...prevState.copied, [type]: true },
    }));

    // 복사가 완료되면 alert 창 표시
    alert('복사되었습니다!');
  };

  render() {
    const { copied } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="subtitle" style={{ backgroundImage: `url('/path/to/images/${authUser.username}.jpg')` }}>
            <h2>내계정 : {authUser.username}</h2>
            <br></br>
            <h3 style={{ color: "red" }}>후원 및 기부</h3>
            <h5>비트코인 : <br></br>1NP9UtdjV1JsgdcEXMSLHodV28AZKF5PNS</h5>
            <button onClick={() => this.handleCopyClick('bitcoin', '1NP9UtdjV1JsgdcEXMSLHodV28AZKF5PNS')}>
              {copied.bitcoin = '복사하기'}
            </button>
            <br></br>
            <h5>이더리움 : <br></br> 0x17bd01125441db0f959d7f7321b9878823fd1f6a</h5>
            <button onClick={() => this.handleCopyClick('ethereum', '0x17bd01125441db0f959d7f7321b9878823fd1f6a')}>
              {copied.ethereum ='복사하기'}
            </button>
            <br></br>
            <h5>리플 : <br></br>rPVMhWBsfF9iMXYj3aAzJVkPDTFNSyWdKy <br></br>Tag: 296310551</h5>
            <button onClick={() => this.handleCopyClick('ripple', 'rPVMhWBsfF9iMXYj3aAzJVkPDTFNSyWdKy')}>
              {copied.ripple= '복사하기'}
            </button>
            <PasswordForgetForm />
            <PasswordChangeForm />
            {/* <ProfileChangeForm/> */}
            <LoginManagement authUser={authUser} />
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

  
    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button
      type="button"
      onClick={() => onLink(signInMethod.provider)}
    >
      Link {signInMethod.id}
    </button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? (
      <button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id}
      </button>
    ) : (
      <form onSubmit={this.onSubmit}>
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="New Password"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm New Password"
        />

        <button disabled={isInvalid} type="submit">
          Link {signInMethod.id}
        </button>
      </form>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPage);
