import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';

import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import DayPage from '../Home';
import StartPage from '../Home2';
import EndPage from '../Home3';
import DayHistoryPage from '../Home4';
import EndHistoryPage from '../Home5';

import AccountPage from '../Account';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
 import Firebase from '../Firebase';

const App = () => (
  <Router>
    <div>
      <Navigation />

      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route path={ROUTES.DAY} component={DayPage} />
      <Route path={ROUTES.START} component={StartPage} />
      <Route path={ROUTES.END} component={EndPage} />
      <Route path={ROUTES.DAY_HISTORY} component={DayHistoryPage} />
      <Route path={ROUTES.END_HISTORY} component={EndHistoryPage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
    </div>
  </Router>
);

export default withAuthentication(App);
